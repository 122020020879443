import * as React from 'react'
import { Button } from 'components/Form/components/Button'
import { InputDate } from 'components/Form/components/InputDate'
import { Model } from 'components/Form/Model'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { observer } from 'mobx-react'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { AppContext } from 'services/connection/models/AppContext'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import { z } from 'zod'
import { isPastDate } from '../helpers/isPastDate'
import { makeObservable, observable, runInAction } from 'mobx'
import { box } from 'services/box'
import { dayjs } from 'helpers/dayjs'

interface Props {}

@observer
export class MeldebogenKornwestheim extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model = new Model<{ date: string }>(
    { date: dayjs().format('YYYY-MM-DD') },
    z.object({ begin: z.string() }),
  )
  @observable private loading: boolean = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private downloadReport = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (!isPastDate(this.model.values.date)) {
      box.alert('Datum ungültig', 'Das Datum darf nicht in der Zukunft liegen.')
      return
    }
    runInAction(() => (this.loading = true))
    await createAndDownloadReport(
      'belegungKornwestheim',
      this.context.instance.id,
      {
        at:
          this.model.values.date === dayjs().format('YYYY-MM-DD')
            ? dayjs().toISOString()
            : dayjs(this.model.values.date).endOf('day').toISOString(),
      },
      `${dayjs(this.model.values.date).format('YYYY MM DD')} Meldebogen EA KWH.xlsx`,
    )
    runInAction(() => (this.loading = false))
  }

  render() {
    if (!this.context.permissions.report_ticketsauswertung) {
      return <Forbidden />
    }

    return (
      <CardColumnCard
        title='Meldebogen EA KWH'
        subtitle='In dieser Tabelle befinden sich Belegungsinformationen.'
      >
        <div className='flex gap-2' id={this.model.id}>
          <div>
            <InputDate label='Datum' name='date' model={this.model} />
          </div>
        </div>
        <Button className='my-4' loading={this.loading} onClick={this.downloadReport}>
          Download
        </Button>
      </CardColumnCard>
    )
  }
}
